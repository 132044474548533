<template>

    <div>
        <v-container fluid>
            <v-overlay :value="overlay"
            opacity="0.7">
                <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                >
                    <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                    >
                    Please wait while we check if you <br> have an existing record with us
                    </v-col>
                    <v-col cols="6">
                    <v-progress-linear
                        color="csb_yellow"
                        indeterminate
                        rounded
                        height="8"
                    ></v-progress-linear>
                    </v-col>
                </v-row>
            </v-overlay>

            <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Loan Calculator</v-subheader>
            </v-row>

            <v-form ref="form">
                <v-row align="center">
                    <v-col class="mr-2" style="padding: 0px;">
                        <v-select 
                            color="csb_yellow"
                            v-model="regionInput" 
                            :items="regions" 
                            item-text="text" 
                            item-value="value" 
                            label="Region Code" 
                            @change="regionSelection()"
                            >
                        </v-select>
                    </v-col>
                    <v-col class="mr-2" style="padding: 0px;">
                        <v-text-field
                            ref="divisionCode"
                            pattern="\d*" 
                            type="tel"
                            v-model="divisionCode"
                            :rules="[rules.required, rules.divisionCode]"
                            counter="3"
                            color="csb_yellow"
                            label="Division Code"
                            @keydown="isAmount('divisionCode')"
                        ></v-text-field>
                    </v-col>
                    <v-col style="padding: 0px;">
                        <v-text-field
                            ref="stationCode"
                            pattern="\d*" 
                            type="tel"
                            v-model="stationCode"
                            :rules="[rules.required, rules.stationCode]"
                            counter="3"
                            color="csb_yellow"
                            label="Station Code"
                            required
                            @keydown="isAmount('stationCode')"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                
                    <v-text-field
                        ref="employeeNumber"
                        pattern="\d*" 
                        type="tel"
                        v-model="employeeNumber"
                        :rules="[rules.required, rules.employeeNumber]"
                        counter="7"
                        color="csb_yellow"
                        label="Employee Number"
                        required
                        @keydown="isAmount('employeeNumber')"
                    ></v-text-field>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        hide-details
                        autocomplete="off"
                        ref="schoolInput"
                        :rules="[rules.required]"
                        :loading="isLoading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="schoolInput" 
                        :items="schools" 
                        :search-input.sync="search"
                        item-text="title" 
                        item-value="school_id" 
                        label="Start typing to search school"
                        @change="getMaxLoanAmount(), getSchoolName()"
                        >

                    
                    </v-autocomplete>
                </v-row>

                <v-row justify="center" align="center" class='helper' >
                    
                    <v-col style="padding: 0px !important; text-align: left; color: rgb(117, 117, 117);" cols="7">
                        <span>{{ school_address }}</span>
                    </v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                </v-row>

                <v-row align="center" style="margin-top: -6px; margin-bottom: 16px;">
                    <v-text-field
                        ref="nthp"
                        pattern="\d*" 
                        type="tel"
                        :rules="[rules.required, rules.nthp]"
                        color="csb_yellow"
                        prefix="₱"
                        v-model="nthp"
                        label="How much is your net take home pay?"
                        @keydown="isAmount('nthp')"
                        hide-details="auto">
                        
                    </v-text-field>
                </v-row>

                <v-row align="center">
                    <v-text-field
                        ref="loan_amount_input"
                        pattern="\d*" 
                        type="tel"
                        :rules="[rules.required, rules.loan_amount_input]"
                        color="csb_yellow"
                        prefix="₱"
                        v-model="loan_amount_input"
                        label="How much is your preferred loan amount?"
                        hide-details
                        :disabled="!checkValidity()"
                        @keydown="isAmount('loan_amount_input')">
                    </v-text-field>
                </v-row>
            </v-form>

            <v-row justify="center" align="center" class='helper' >
                <v-col style="padding: 0px !important; color: black;">{{min_loan_amount | formatAmount}}.00</v-col>
                <v-col style="padding: 0px !important; text-align: center; color: rgb(117, 117, 117);" cols="7">
                    <span v-if="!checkValidity()">{{ warningMessage }}</span>
                    <span v-if="!checkLoanAmount('max') && this.loan_amount_input">Exceeds maximum loanable amount</span>
                    <span v-if="!checkLoanAmount('min') && this.loan_amount_input">Exceeds minimum loanable amount</span>
                </v-col>
                <v-col style="padding: 0px !important; color: black; text-align: right;">{{max_loan_amount | formatAmount}}.00</v-col>
            </v-row>
            <br>

            <v-row justify="center" align="center" class='helper' >
                <p class="label-text" style="font-size:13.5px;color: #757575;">What is your preferred payment term (in months)?</p>
            </v-row>
            
            <v-row class="radio-cont">
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="12_month" type="radio" v-model="payment_term" value="12" hidden checked>
                    <label class="" for="12_month">
                        12
                    </label>
                </v-col>
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="24_month" type="radio" v-model="payment_term" value="24" hidden>
                    <label class="" for="24_month">
                        24
                    </label>
                </v-col>
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="36_month" type="radio" v-model="payment_term" value="36" hidden>
                    <label class="" for="36_month">
                        36
                    </label>
                </v-col>
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="48_month" type="radio" v-model="payment_term" value="48" hidden>
                    <label class="" for="48_month">
                        48
                    </label>
                </v-col>
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="60_month" type="radio" v-model="payment_term" value="60" hidden>
                    <label class="" for="60_month">
                        60
                    </label>
                </v-col>
            </v-row>


            <v-row class="calc-details">
                <v-col class="radio-cont">
                    <div class="calc-details-text">
                        Monthly Amortization
                    </div>
                    {{monthly_amo | formatAmount}}
                    <br><br>
                    <div class="calc-details-text">
                        NTHP after deduction
                    </div>
                    {{nthp_after_deduction | formatAmount}}
                </v-col>
                <v-col class="radio-cont">
                    <div class="calc-details-text">
                        Bank Charge
                    </div>
                     {{processing_fee | formatAmount}}
                    <br><br>
                    <div class="calc-details-text">
                        Net Proceeds
                    </div>
                    {{net_proceeds | formatAmount}}
                </v-col>
            </v-row>

            <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Promo Code</v-subheader>
            </v-row>

            <v-row align="center">
                <v-text-field
                    ref="promo_code"
                    color="csb_yellow"
                    v-model="promo_code"
                    label="Please enter promo code here"
                    hide-details>
                </v-text-field>
            </v-row>

            <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                >
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
            </v-snackbar>
            
        </v-container>
        <v-dialog
            v-model="dialog"
            max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5">
                Warning
                </v-card-title>

                <v-card-text>
                Upon checking, you still have an existing loan with us.
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="csb_yellow"
                    text
                    @click="handleOkDialog()"
                >
                    Ok
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row  align="center" justify="space-around" class="bottom-nav" style="margin-top: -10px !important;">

            <p class="label-text mt-8" style="text-align: center; font-size:12px;color: #757575;">Processing fee is inclusive of Taxes</p>
            
            <v-btn @click="handleNext()" depressed class="bottom-nav-btn">
                Next
            </v-btn>

        </v-row>
    </div>
</template>

<script>

import { getSchools, searchCustomer, checkExistingLoans } from '@/api/calculator'
import { chatFuel } from '@/api/attachment'
import { mapGetters } from 'vuex'

const regions = [
    {text: "001", value: "001"},
    {text: "002", value: "002"},
    {text: "003", value: "003"},
    {text: "04A", value: "04A"},
    {text: "04B", value: "04B"},
    {text: "005", value: "005"},
    {text: "006", value: "006"},
    {text: "007", value: "007"},
    {text: "008", value: "008"},
    {text: "009", value: "009"},
    {text: "010", value: "010"},
    {text: "011", value: "011"},
    {text: "012", value: "012"},
    {text: "CARAGA", value: "CRG"},
    {text: "ARMM", value: "ARMM"},
    {text: "CAR", value: "CAR"},
    {text: "NCR", value: "NCR"}
]

export default {
    computed: {

        ...mapGetters([
            'calculator',
            'profileFormIsValid',
            'othersFormIsValid',
            'fb_fname',
            'fb_lname',
            'fb_messengerid',
            'valid_customer'
 
        ]),

        warningMessage(){
            if(!this.isMounted)
                return;

            if(!this.$refs.schoolInput.valid && ((this.$refs.nthp.valid && (this.convertNumber(this.nthp) >= this.minimum_nthp)) == false)){
                return 'Enter Net take home pay first'
            } else if(!this.$refs.schoolInput.valid) {
                return 'Enter School first'
            } else {
                return 'Enter Net take home pay first'
            }
        },
    },

    async beforeRouteLeave(to, from, next) {

        if(to.name == 'InvalidCustomer'){
            next()
        } else {
     
            if(this.$refs.form.validate()){
                var data = {
                    regions: this.regions,
                    regionInput: this.regionInput,
                    divisionCode: this.divisionCode,
                    stationCode: this.stationCode,
                    employeeNumber: this.employeeNumber,
                    schoolInput: this.schoolInput, // school code
                    schoolName: this.schoolName,
                    schools: this.schools,
                    search: this.search,
                    isLoading: this.isLoading,
                    nthp: this.nthp,
                    minimum_nthp: this.minimum_nthp,
                    loan_amount_input: this.loan_amount_input,
                    payment_term: this.payment_term,
                    loan_amount: this.loan_amount,
                    monthly_amo: this.monthly_amo,
                    processing_fee: this.processing_fee,
                    net_proceeds: this.net_proceeds,
                    min_loan_amount: this.min_loan_amount,
                    max_loan_amount: this.max_loan_amount,
                    rate: this.rate,
                    bank_charge: this.bank_charge,

                    promo_code: this.promo_code
                }

                const confirmed = await this.$confirm('Please confirm if all the details entered are correct before you proceed.')

                if(confirmed) {

                    let paramsSearch = {
                        filter: 'EmployeeId',
                        region_code: this.regionInput,
                        keyword: this.employeeNumber,
                        station_code: this.stationCode,
                        division_code: this.divisionCode,
                    }
                    
                    try {
                        this.overlay = true
                        let responseSearch = await searchCustomer(paramsSearch)
                        
                        let res = []

                        if(responseSearch.data.data.length > 0) {
                            res = responseSearch.data.data[0]
                        }
                        
                        if(res && res.core_cust_id) {
                            let paramsCheck = {
                                core_cust_id: res.core_cust_id,
                                division_code: this.divisionCode,
                                station_code: this.stationCode,
                                employee_no: this.employeeNumber,
                            }

                            try {

                                let responseCheck = await checkExistingLoans(paramsCheck)
                                this.overlay = false

                                if(responseCheck.data.data.length > 0) {
                                    this.valid_deped_id = false
                                    this.$store.dispatch('user/validCustomer', false)
                                } else {
                                    this.valid_deped_id = true
                                    this.$store.dispatch('user/validCustomer', true)
                                }

                            } catch(error){
                                //handle your error here with whatever status you need
                                this.text = 'Error in check existing loans: ' + error
                                this.snackbar = true
                                this.overlay = false
                                return []
                            }
                        } else {
                            this.overlay = false
                            this.valid_deped_id = true
                            this.$store.dispatch('user/validCustomer', true)
                        }


                        if(this.valid_deped_id == true) {
                            if(to.name == 'Profile'){
                                next()
                            } else if(to.name == 'Others'){
                                if(this.profileFormIsValid == true){
                                    next();
                                }
                            } else if(to.name == 'Attachment'){
                                if(this.profileFormIsValid && this.othersFormIsValid){
                                    next();
                                }
                            }
                            
                            this.$store.dispatch('user/calculator', data)
                        } else {
                            this.dialog = true
                            // this.text = 'Loan already exists'
                            // this.snackbar = true 
                            // return []
                        }

                    } catch(error){
                        //handle your error here with whatever status you need
                        this.text = 'Error in search customer: ' + error
                        this.snackbar = true
                        this.overlay = false
                        return []
                    }

                }

            }
        }

    },
  
    data: function() {
        return {
            isMounted: false,
            
            regions: regions,
            regionInput: '',

            divisionCode: '',
            stationCode: '',
            employeeNumber: '',

            // school input
            schoolInput: '',
            schools: [],
            schoolName: '',
            search: null,
            isLoading: false,

            nthp: '',
            minimum_nthp: 0,
            loan_amount_input: '',
            payment_term :'60',

            loan_amount: "0.00",
            monthly_amo: "0.00",
            processing_fee: "0.00",
            net_proceeds: "0.00",
            nthp_after_deduction: "0.00",

            min_loan_amount: 0,
            max_loan_amount: 0,
            rate: 0,
            bank_charge: '6%',

            //rules
            rules: {
                required: v => !!v || 'Required.',
                divisionCode: v => v.length == 3,
                stationCode: v => v.length == 3,
                employeeNumber: v => v.length == 7,
                nthp: v => (this.convertNumber(v) >= this.minimum_nthp) || 'Min of ₱ ' + this.minimum_nthp.toLocaleString(),
                loan_amount_input: () => this.checkLoanAmount() == true
                
            },

            snackbar: false,
            text: 'Only images are accepted as signature.',
            timeout: 2000,

            promo_code: '',

            valid_deped_id: false,

            overlay: false,

            dialog: false,

            school_address: '',
        }
    },

    watch: {
        search (val) {

            if(val == null || val.length < 3){
                this.schools = []
                this.school_address = ''
                return
            } else if(val.length === 3) {

                this.isLoading = true
                // Lazily load input items
                this.getDepedSchools(val)
                .then(res => {
                    this.schools = res
                })
                .finally(() => (this.isLoading = false))
            }
        },

        nthp: function(){
            
            this.getMaxLoanAmount()
            this.getLoanDetails()
        },
        loan_amount_input: function(){
            this.getLoanDetails()
        },
        payment_term: function(){
            this.minimum_nthp = 0
            this.getMaxLoanAmount()
            this.getLoanDetails()
        }

    },
    filters: {
        formatAmount: function(value){
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    methods:{
        handleNext(){
            this.$router.push({ path: '/profile/index' })
        },
        async handleOkDialog(){
            await this.callChatFuel()
            this.$router.replace({ path: '/invalid-customer' })
        },
        callChatFuel: async function(){

            var submit_data={
                "fb_fname" : this.fb_fname,
                "fb_lname" : this.fb_lname,
                "messenger_id": this.fb_messengerid
            }

            chatFuel(submit_data, {product: 'DEPED-EXIST'}).catch(error => {
                this.$log.error(error);
            });
        },
        getSchoolName(){
            if(this.schoolInput) {
                var name = this.schools.filter(i => { return i.school_id == this.schoolInput })
                this.schoolName = name[0].school_name

                if(name[0].street_address) {
                    this.school_address = name[0].street_address
                } else {
                    this.school_address = 'Address Unavailable'
                }

                
            }
        },
        getDepedSchools(school){
            return getSchools(this.regionInput, {school_name: school}).then(response => {

                let res = response.data.data

                return res.map(i => {

                    i.title = i.school_name + ' - ' + i.school_id

                    return i
                })
                
            }).catch((error) => {
                this.text = 'Error: ' + error
                this.snackbar = true 
                return []
            });
        },
        regionSelection(){
            this.schoolInput = ''
            this.schools = []
            this.search = null
        },

        checkValidity: function(){
            if(!this.isMounted)
                return;

            if(this.$refs.nthp.valid) {
                return true
            }
            else 
                return false
        },
        checkLoanAmount: function(val=''){
            var loan_amount_input = (this.loan_amount_input) ? this.convertNumber(this.loan_amount_input) : ''
            var ok_min = (loan_amount_input >= this.min_loan_amount)
            var ok_max = (loan_amount_input <= this.max_loan_amount)

            if(this.max_loan_amount!=0 ){
                if(val){
                    if(val=='min')
                        return ok_min
                    else if(val=='max')
                        return ok_max
                }else{
                    return (ok_min && ok_max)
                }
            }
            else return false
        },
        getMaxLoanAmount: function(){
     
            var max_monthly_amo = this.convertNumber(this.nthp) - 5000
            
            switch(this.payment_term) {
            case '12':
                this.rate = '7.50%'
                break;
            case '24':
                this.rate = '9.00%'
                break;
            case '36':
                this.rate = '9.66%'
                break;
            case '48':
                this.rate = '9.66%'
                break;
            case '60':
                this.rate = '9.66%'
                break;
            default:
                // code block
            }

            this.rate = this.convertNumber(this.rate) / 100
            this.max_loan_amount = this.computeMaxLoanAmount(this.rate, this.payment_term, max_monthly_amo)
            if(this.max_loan_amount < 5000){
                var temp = this.computeMonthlyAmo(this.rate, this.payment_term, 5000)
                this.minimum_nthp = Math.ceil(temp) + 5000
                
                this.max_loan_amount = 0
                this.min_loan_amount = 0
                this.loan_amount_input = ''
                this.$refs.loan_amount_input.reset()
                this.$refs.nthp.validate()

            } else {
                this.$refs.nthp.validate()
                this.max_loan_amount = (this.max_loan_amount > 1500000) ? 1500000 : this.max_loan_amount
                this.min_loan_amount = 5000

            }
    
        },
        computeMaxLoanAmount: function(rate, nper, pmt){
            rate = rate / 12;
            var amount = pmt / rate * (1 - Math.pow(1 + rate, -nper));
            //round DOWN neareast thousand
            amount = Math.floor(amount / 1000) * 1000

            return amount;
        },
        getLoanDetails: function() {
            if( this.checkValidity() && this.loan_amount_input && this.checkLoanAmount() ){
                var loan_amount_input = this.convertNumber(this.loan_amount_input)

                this.loan_amount = loan_amount_input.toFixed(2)
                this.monthly_amo = this.computeMonthlyAmo(this.rate, this.payment_term, loan_amount_input)
                this.processing_fee = ( loan_amount_input * (this.convertNumber(this.bank_charge) / 100) ).toFixed(2)
                this.net_proceeds = (loan_amount_input - this.processing_fee).toFixed(2)
                this.nthp_after_deduction = (this.convertNumber(this.nthp) - this.convertNumber(this.monthly_amo)).toFixed(2)

            }else{
                this.loan_amount = "0.00"
                this.monthly_amo = "0.00"
                this.processing_fee = "0.00"
                this.net_proceeds = "0.00"
                this.nthp_after_deduction  = "0.00"
            }
        },
        computeMonthlyAmo(rate, term, loan_amount){ 
            rate = rate / 12
            term = term * -1
            var monthly_amo = ( (loan_amount * rate) / (1 - (Math.pow( 1 + rate, term))) )
            
            return monthly_amo.toFixed(2)
        },
        validateCalculator: function(){
            this.$v.$touch()
            
            return (!this.$v.$invalid && this.school_names.includes(this.school_input) && this.checkLoanAmount())
        },
        convertNumber: function(value){
            return  Number(value.replace(/[^0-9.-]+/g,""));
        },
        isAmount: function(field){
            var charCode = (event.which) ? event.which : event.keyCode;
            
            if ((charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) && charCode !== 46 && charCode !== 188 && charCode !== 190) {
                event.preventDefault();
            } else {
                event.preventDefault();
                var valueString = event.target.value

                if(charCode==8 || charCode==46)
                    valueString = valueString.slice(0, -1)
                else if(field=='divisionCode'){
                    valueString = valueString + event.key
                    if(valueString.length > 3) return false
                }
                else if(field=='stationCode'){
                    valueString = valueString + event.key
                    if(valueString.length > 3) return false
                }
                else if(field=='employeeNumber'){
                    valueString = valueString + event.key
                    if(valueString.length > 7) return false
                }
                else if(field=='nthp'){
                    valueString = valueString + event.key
                    if(valueString.length > 7) return false
                }
                else if(field=='loan_amount_input')
                    valueString = valueString + event.key

                var str = valueString.replace(/[^0-9]/g,'')

                if(field == 'nthp' || field == 'loan_amount_input'){
                    var formattedString = str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    this[field] = formattedString
                } else
                    this[field] = str
            }
        },
    },
    created() {
        this.regionInput = '001'
    },
    mounted(){
        this.isMounted = true
        if(this.fb_messengerid == '' ){ 
            var fbInfo = {
                fb_fname: this.$route.query.fb_fname, 
                fb_lname: this.$route.query.fb_lname, 
                fb_messengerid: this.$route.query.fb_messengerid, 
                fb_gender: this.$route.query.fb_gender, 
                fb_profilepic: this.$route.query.fb_profilepic
            }
        }
        this.$store.dispatch('user/fbInfo', fbInfo)
    }
}
</script>