import request from '@/utils/request'

import requestAws from '@/utils/requestAWS'

export function getSchools(region, params) {
  return request({
    url: 'maria/schools/' + region,
    method: 'get',
    params
  })
}

export function searchCustomer(params) { // ?filter=EmployeeId&keyword=4697199&station_code=539&division_code=303
  return requestAws({
    url: 'core/inquiry/customer',
    method: 'get',
    params
  })
}

export function checkExistingLoans(params) { // ?core_cust_id=103296&division_code=303&station_code=539&employee_no=4697199
  return requestAws({
    url: 'core/inquiry/loans',
    method: 'get',
    params
  })
}