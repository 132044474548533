import request from '@/utils/request'

export function submitCustomerData(data) {
  return request({
    url: 'customer?nospouse=1',
    method: 'post',
    data
  })
}

export function submitCustomerAttachment(data) {
  return request({
    url: 'customer/upload/file',
    method: 'post',
    data
  })
}

export function chatFuel(data, params) {
  return request({
    url: 'maria/chatfuel',
    method: 'post',
    data,
    params
  })
}

export function submitMariaApplication(data) {
  return request({
    url: 'maria/deped',
    method: 'post',
    data
  })
}

export function submitMariaAttachment(data) {
  return request({
    url: 'maria/upload/file',
    method: 'post',
    data
  })
}